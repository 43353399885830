import {
    BrowserUtils,
    ComponentElements,
    ComponentInjector,
    ComponentObject,
    ComponentSelector,
    ComponentSelectors,
    ComponentService,
    Inject,
    UIComponent
} from '@atypon/ui-utils';

class Texts extends ComponentObject { }

class ClassList extends ComponentObject {
    isOpen = 'isOpen'
}

class Selectors extends ComponentSelectors {
    topicalIndexVertical = new ComponentSelector('.topical-index--vertical', 'document');
    topicalIndexHorizontalItems = new ComponentSelector('.topical-index--horizontal .topical-index__list > .topical-index__list__item', 'document');
    topicalIndexHorizontalLists = new ComponentSelector('.topical-index--horizontal .topical-index__list__item > .topical-index__list--nested', 'document');
    topicalIndexHorizontalWrapper = new ComponentSelector('.topical-index--horizontal .topical-index__list', 'document');
    topicalIndexItems = new ComponentSelector('.topical-index--vertical .topical-index__list > .topical-index__list__item', 'document');
}

class Elements extends ComponentElements<Selectors> {
    topicalIndexVertical: HTMLElement = this.domUtils.getElement(this.selectors.topicalIndexVertical.selector);
    topicalIndexHorizontalItems: HTMLElement[] = this.domUtils.getElements(this.selectors.topicalIndexHorizontalItems.selector);
    topicalIndexHorizontalLists: HTMLElement[] = this.domUtils.getElements(this.selectors.topicalIndexHorizontalLists.selector);
    topicalIndexHorizontalWrapper: HTMLElement = this.domUtils.getElement(this.selectors.topicalIndexHorizontalWrapper.selector);
    topicalIndexItems: HTMLLIElement[] = this.domUtils.getElements(this.selectors.topicalIndexItems.selector);
}

interface TopicalIndex extends ComponentService<Selectors, Elements, ClassList, Texts> {
}

@ComponentInjector(Selectors, Elements, ClassList, Texts)
class TopicalIndex implements UIComponent {
    @Inject()
    protected browserUtils: BrowserUtils;

    constructor(readonly wrapper: HTMLElement) {
    }

    initialize(): void {
        this.addEventListeners();
    }

    addEventListeners(): void {
        this.toggleItem();
        this.scrollToItem();
    }

    toggleItem(): void {
        this.elements.topicalIndexItems.forEach((item: HTMLLIElement) => {
            item.querySelector(".topical-index__item").addEventListener("click", () => {
                if (this.elements.topicalIndexVertical.classList.contains("isOpen") && item.classList.contains("isOpen")) {
                    this.elements.topicalIndexVertical.classList.remove("isOpen");
                    item.classList.remove("isOpen")
                } else {
                    this.elements.topicalIndexItems.forEach(el => {
                        el.classList.remove("isOpen");
                    })
                    this.elements.topicalIndexVertical.classList.add("isOpen");
                    item.classList.add("isOpen")
                }
            })
        })
    }

    scrollToItem(): void {    
        let currentOpenPosition: number | undefined;
        let isOpen = false;
    
        this.elements.topicalIndexHorizontalItems.forEach((item:any) => {
            item.addEventListener("click", () => {
                for (let i = 0; i < this.elements.topicalIndexHorizontalLists.length; i++) {
                    const el :any = this.elements.topicalIndexHorizontalLists[i];
                    if (el.classList.contains("show")) {
                        currentOpenPosition = el.offsetTop;
                        isOpen = true;
                        break;
                    }
                    isOpen = false;
                }
    
                if (isOpen && currentOpenPosition !== undefined && item.offsetTop > currentOpenPosition) {
                   this.elements.topicalIndexHorizontalWrapper?.scrollIntoView();
                }
            });
        });
    }
}

export default TopicalIndex;

export {
    Elements as TopicalIndexElements,
    Selectors as TopicalIndexSelectors,
    ClassList as TopicalIndexClassList,
    Texts as TopicalIndexTexts,
}