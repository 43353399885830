import Base, {
  HeaderItemsClonerTexts,
  HeaderItemsClonerClassList,
  HeaderItemsClonerElements,
  HeaderItemsClonerSelectors,
} from "ui-theme/components/header/header-items-cloner";
import { BaseInjector } from "ui-theme/js/base";
import { HeaderElements } from "./index";

class Texts extends HeaderItemsClonerTexts {}

class ClassList extends HeaderItemsClonerClassList {}

class Selectors extends HeaderItemsClonerSelectors {}

class Elements extends HeaderItemsClonerElements {}

interface HeaderItemsCloner {
  get elements(): Elements;
  get texts(): Texts;
  get classList(): ClassList;
  get selectors(): Selectors;
}

@BaseInjector(Selectors, Elements, ClassList, Texts)
class HeaderItemsCloner extends Base {
  constructor(
    wrapper: HTMLElement,
    protected readonly headerElements: HeaderElements
  ) {
    super(wrapper);
  }
  setElements() {
    super.setElements();
    this.cloneBannerContent();
  }

  cloneBannerContent() {
    if (
      !this.headerElements.bannerContent ||
      !this.headerElements.publicationMenuLogo
    ) {
      return;
    }
    const cloned = this.headerElements.bannerContent.cloneNode(
      true
    ) as HTMLElement;
    this.headerElements.publicationMenuLogo.insertAdjacentElement(
      "afterbegin",
      cloned
    );
  }

  itemHTML(item: HTMLElement): string {
    if (item.classList.contains("more-links")) {
      return "";
    }
    return super.itemHTML(item);
  }

  get subItemsWrapper() {
    return `<ul class="header-sidebar__nav list-unstyled nav flex-column border-bottom pb-24 mb-24"></ul>`;
  }

  addSubItemsToSidebar(parent: Element): void {
    const list: NodeListOf<HTMLElement> =
      this.elements.secondaryMenu?.querySelectorAll(this.selectors.cloneLink);
    let subItemsParent: Element;
    list.forEach((item: HTMLElement) => {
      if (item.classList.contains("no-clone")) return;
      if (!subItemsParent) {
        const element = document.createElement("div");
        element.insertAdjacentHTML("afterbegin", this.subItemsWrapper);
        subItemsParent = parent.insertAdjacentElement(
          "beforebegin",
          element.children[0]
        );
      }

      if (
        item.nextElementSibling &&
        item.nextElementSibling.classList.contains("dropdown-menu")
      ) {
        this.addSubListDropdown(subItemsParent, item);
      } else {
        subItemsParent.insertAdjacentHTML(
          "beforeend",
          this.subListItemHTML(item, this.classes.subMenuLink)
        );
      }
    });
  }

  subListItemHTML(item: Element, classes?: string): string {
    return super
      .subListItemHTML(item, classes)
      .replace("font-weight-normal", "");
  }

  addSubListDropdown(parent: Element, item: Element) {
    const random = Math.floor(Math.random() * 100);
    const target = `#dropdown-${random}`;
    const dropdownItems =
      item.nextElementSibling.querySelectorAll(".dropdown-item a");
    const dropdownItemsHTML = this.getDropdownItemsHTML(dropdownItems);
    const html = `
                    <li class="nav-item nav-item--subitem">
                        <a href="${target}" class="dropdown-toggle ${this.classes.menuLink}" data-toggle="collapse" data-target="${target}" aria-expanded="false" 
                        aria-controls="dropdown-${random}">
                            ${item.textContent}
                        </a>
                        <ul class="dropdown collapse list-unstyled" id="dropdown-${random}">
                            ${dropdownItemsHTML}
                        </ul>
                    </li>`;

    parent.insertAdjacentHTML("beforeend", html);
  }
}

export default HeaderItemsCloner;

export {
  Elements as HeaderItemsClonerElements,
  Selectors as HeaderItemsClonerSelectors,
  ClassList as HeaderItemsClonerClassList,
  Texts as HeaderItemsClonerTexts,
};
