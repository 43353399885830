import EurekaModule from "ui-theme/js/base/EurekaModule";
import modules from "ui-theme/js/base/init";
import { querySelector, querySelectorAll } from "ui-theme/utility/utils";
import Header from "../components/header";
import QuickSearch from "../widgets/quick-search";
import AdaptiveMenu, { AdaptiveMenuType } from "../widgets/menu/AdaptiveMenu";
import TopicalIndex from "../widgets/topical-index/topical-index";

const headerIndex = modules.findIndex((item) => item.name === "header");

const quickSearchIndex = modules.findIndex(
  (item) => item.name === "quick-search"
);

if (~headerIndex) {
  modules[headerIndex].handler = () => {
    const header = querySelector(".main-header");
    if (header) {
      new Header(header).initialize();
    }
  };
}

if (~quickSearchIndex) {
  modules[quickSearchIndex].handler = () => {
    new QuickSearch().initialize();
  };
}

const topicalIndex = querySelector(".topical-index--vertical, .topical-index--horizontal")
if (topicalIndex) {
  new TopicalIndex(topicalIndex).initialize()
}

modules.push(
  new EurekaModule("amps-bottom-sheet", async () => {
    //dynamic loading
    const bottomSheets = querySelectorAll(".amps-bottom-sheet");
    if (bottomSheets.length) {
      const { default: BottomSheet } = await import(
        /* webpackChunkName: '@amps/kom/bottom-sheet', webpackPrefetch: true  */ "@atypon/amps/components/@amps/kom/bottom-sheet"
      );
      bottomSheets.forEach((item) => {
        new BottomSheet(item).initialize();
      });
    }
  }),
  new EurekaModule("adaptive-menu", async () => {
    const primary = document.getElementById("main-menu");
    if (primary) {
      new AdaptiveMenu(primary, AdaptiveMenuType.PRIMARY).initialize();
    }

    querySelectorAll(".publication__menu").forEach((item) => {
      new AdaptiveMenu(item, AdaptiveMenuType.SECONDARY).initialize();
    });
  })
);

export default modules;
