import { BaseInjector } from "ui-theme/js/base";
import BaseHeader, {
  HeaderTexts,
  HeaderClassList,
  HeaderSelectors,
  HeaderElements,
} from "ui-theme/components/header";
import { querySelector } from "ui-theme/utility/utils";
import Scrolling from "./scrolling";
import HeaderItemsCloner from "./header-items-cloner";

class Texts extends HeaderTexts {}

class ClassList extends HeaderClassList {}

class Selectors extends HeaderSelectors {
  bannerContent = ".banner-title";
  publicationMenuLogo = ".publication__menu__journal";
}

class Elements extends HeaderElements {
  bannerContent: HTMLElement;
  publicationMenuLogo: HTMLElement;
  selectors: Selectors;

  initElements() {
    super.initElements();

    this.bannerContent = querySelector(this.selectors.bannerContent);
    this.publicationMenuLogo = querySelector(
      this.selectors.publicationMenuLogo,
      this.publicationMenu
    );
  }
}

interface Header {
  get elements(): Elements;
  get texts(): Texts;
  get classList(): ClassList;
  get selectors(): Selectors;
}

@BaseInjector(Selectors, Elements, ClassList, Texts)
class Header extends BaseHeader {
  initialize() {
    super.initialize();
    new Scrolling(this.elements).initialize();
    new HeaderItemsCloner(this.wrapper, this.elements).initialize();
  }
}

export default Header;

export {
  Texts as HeaderTexts,
  ClassList as HeaderClassList,
  Elements as HeaderElements,
  Selectors as HeaderSelectors,
};
