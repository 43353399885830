import BaseScrolling, {
  HeaderClassList as BaseScrollingClassList,
  HeaderElements as BaseScrollingElements,
  HeaderSelectors as BaseScrollingSelectors,
  HeaderTexts as BaseScrollingTexts,
} from "ui-theme/components/header/scrolling";
import { BaseInjector } from "ui-theme/js/base";
import { HeaderClassList, HeaderElements, HeaderSelectors } from "./index";
import { querySelector } from "ui-theme/utility/utils";

class Texts extends BaseScrollingTexts {}
class ClassList extends BaseScrollingClassList {
  VISIBLE = "visible";
}
class Selectors extends BaseScrollingSelectors {}
class Elements extends BaseScrollingElements {}

interface Scrolling {
  get elements(): Elements;
  get texts(): Texts;
  get classList(): ClassList;
  get selectors(): Selectors;
}

@BaseInjector(Selectors, Elements, ClassList, Texts)
class Scrolling extends BaseScrolling {
  constructor(protected readonly headerElements: HeaderElements) {
    super();
  }

  initialize() {
    super.initialize();
    this.scrollHandler();
  }

  scrollHandler(): void {
    super.scrollHandler();
    if (!this.elements.publicationMenu) {
      return;
    }
    const adsWrappersHeight = this.elements.adsWrappers?.offsetHeight || 0;
    const bannerHeight =
      this.headerElements.bannerContent?.parentElement?.offsetHeight || 0;
    const publicationMenuHeight =
      this.elements.publicationMenu.offsetHeight || 0;

    const currentScrollPos = window.scrollY;

    const { height: headerHeight, top: headerTop } =
      this.elements.header.getBoundingClientRect();

    const top =
      headerHeight + bannerHeight + adsWrappersHeight - publicationMenuHeight;

    this.headerElements?.publicationMenuLogo?.classList.toggle(
      this.classList.VISIBLE,
      currentScrollPos > top
    );

    if (!this.isSidebarOpened) {
      this.elements.header.style.top = `-${adsWrappersHeight + headerHeight}px`;
      this.elements.publicationMenu.style.top = `0`;
    } else {
      this.elements.header.style.top = `0`;
      this.elements.publicationMenu.style.top = `${headerHeight}px`;
    }
  }
}

export default Scrolling;

export {
  Elements as ScrollingElements,
  Selectors as ScrollingSelectors,
  ClassList as ScrollingClassList,
  Texts as ScrollingTexts,
};
