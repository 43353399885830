import { BaseInjector } from "ui-theme/js/base";
import Base, {
  QuickSearchElements as BaseElements,
  QuickSearchSelectors as BaseSelectors,
  QuickSearchClassList as BaseClassList,
  QuickSearchTexts as BaseTexts,
} from "ui-theme/widgets/quick-search";

class Texts extends BaseTexts {}

class ClassList extends BaseClassList {}

class Selectors extends BaseSelectors {
  activeQuickSearchForm = ".header-with-sidebar__quick-search form";
}

class Elements extends BaseElements {
  selectors: Selectors;

  initElements() {
    super.initElements();
  }
}

interface QuickSearch {
  get elements(): Elements;
  get texts(): Texts;
  get classList(): ClassList;
  get selectors(): Selectors;
}

@BaseInjector(Selectors, Elements, ClassList, Texts)
class QuickSearch extends Base {}

export default QuickSearch;

export {
  Elements as QuickSearchElements,
  Selectors as QuickSearchSelectors,
  ClassList as QuickSearchClassList,
  Texts as QuickSearchTexts,
};
