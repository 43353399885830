import {
  BaseElements,
  BaseObject,
  BaseService,
  BaseInjector,
} from "ui-theme/js/base";
import { querySelector } from "ui-theme/utility/utils";

class Texts extends BaseObject {}

class ClassList extends BaseObject {
  DROPDOWN_ITEM = "dropdown-item";
  DROPDOWN_TOGGLE = "dropdown-toggle";
  NAV_LINK = "nav-link";
  HIDDEN = "d-none";
  DROPDOWN_TYPOGRAPHY = "text-mini-bold-uppercase";
  PUBLICATION_MENU_LINK = "publication__menu__link";
}

class Selectors extends BaseObject {
  moreLinks = ".more-links";
  moreLinksDropdown = ".dropdown-menu";
  moreLinksNavLink = ".dropdown-toggle";
  main = "main";
  link = "a:not(.cloned)";
}

class Elements extends BaseElements<Selectors> {
  moreLinks: HTMLElement;
  moreLinksDropdown: HTMLElement;
  moreLinksNavLink: HTMLElement;
  main: HTMLElement;
  selectors: Selectors;

  constructor(selectors: Selectors) {
    super(selectors, false);
  }

  initElements() {
    super.initElements();
    this.moreLinks = querySelector(this.selectors.moreLinks, this.wrapper);
    this.moreLinksDropdown = querySelector(
      this.selectors.moreLinksDropdown,
      this.moreLinks
    );
    this.moreLinksNavLink = querySelector(
      this.selectors.moreLinksNavLink,
      this.moreLinks
    );
    this.main = document.getElementById(this.selectors.main);
  }
}

export enum AdaptiveMenuType {
  PRIMARY,
  SECONDARY,
}
interface AdaptiveMenu
  extends BaseService<Selectors, Elements, ClassList, Texts> {}

@BaseInjector(Selectors, Elements, ClassList, Texts)
class AdaptiveMenu {
  get maxHeight() {
    return this.type === AdaptiveMenuType.PRIMARY ? 38 : 52;
  }

  dropdownItems: [Element, Element][] = [];

  constructor(
    protected wrapper: HTMLElement,
    protected type: AdaptiveMenuType
  ) {}

  setElements() {
    this.elements.wrapper = this.wrapper;
    this.elements.initElements();
  }

  addEventListeners() {
    window.addEventListener("resize", this.toggleDropdownItems.bind(this));
    if (this.type !== AdaptiveMenuType.PRIMARY) {
      window.addEventListener("scroll", this.toggleDropdownItems.bind(this));
    }
  }

  initialize() {
    this.setElements();
    this.moveToDropDown(this.elements.moreLinks);
    this.setMoreLinkText();
    this.toggleDropdownItems();
    this.addEventListeners(); 
    return this;
  }

  toggleDropdownItems() {
    this.reset();
    let index = 0;

    if (this.type !== AdaptiveMenuType.PRIMARY) {
      console.log(this.wrapper.getBoundingClientRect().height);
    }
    while (
      this.wrapper.getBoundingClientRect().height > this.maxHeight &&
      index < this.dropdownItems.length
    ) {
      const [navbarItem, dropdownItem] = this.dropdownItems[index];

      navbarItem.classList.add(this.classList.HIDDEN);
      dropdownItem.classList.remove(this.classList.HIDDEN);
      index++;
    }
    if (index === 0) {
      this.elements.moreLinks.classList.add(this.classList.HIDDEN);
    }
  }

  reset() {
    this.dropdownItems.forEach(([navbarItem, dropdownItem]) => {
      navbarItem.classList.remove(this.classList.HIDDEN);
      dropdownItem.classList.add(this.classList.HIDDEN);
    });

    this.elements.moreLinks.classList.remove(this.classList.HIDDEN);
  }

  setMoreLinkText() {
    if (!this.elements.moreLinksNavLink || !this.elements.main) {
      return;
    }
    const dataset = this.elements.main.dataset;
    this.elements.moreLinksNavLink.textContent =
      this.type === AdaptiveMenuType.PRIMARY
        ? dataset.headerMoreLinkText
        : dataset.secondaryMoreLinkText;
  }

  moveToDropDown(item: Element) {
    const prevElement = item.previousElementSibling as HTMLElement;
    if (!prevElement) {
      return;
    }
    const prevLink = querySelector(this.selectors.link, prevElement);
    if (!prevLink) {
      return;
    }
    let element: Element = null;
    if (
      prevLink.classList.contains(this.classList.DROPDOWN_TOGGLE) &&
      prevLink.nextElementSibling.hasChildNodes()
    ) {
      element = this.elements.moreLinksDropdown.insertAdjacentElement(
        "afterbegin",
        this.resolveDropdownItems(prevLink)
      );
    } else {
      element = this.resolveNavLink(prevLink);
    }
    this.dropdownItems.push([prevElement, element]);

    return this.moveToDropDown(prevElement);
  }

  resolveNavLink(prevLink: HTMLElement) {
    const cloned = prevLink.cloneNode(true) as HTMLElement;
    const element = this.elements.moreLinksDropdown.insertAdjacentElement(
      "afterbegin",
      cloned
    );
    element.classList.add(this.classList.DROPDOWN_ITEM);
    element.classList.remove(this.classList.NAV_LINK, this.classList.HIDDEN);
    return element;
  }

  resolveDropdownItems(prevLink: HTMLElement) {
    const wrapper = document.createElement("div");

    wrapper.insertAdjacentHTML(
      "afterbegin",
      `<div class="dropdown-divider"></div><h6 class="dropdown-header text-micro-bold-uppercase">${prevLink.textContent}</h6>`
    );

    Array.from(prevLink.nextElementSibling.children).forEach((item) => {
      const cloned = item.cloneNode(true) as HTMLElement;
      const element = wrapper.insertAdjacentElement("beforeend", cloned);
      element.classList.remove(this.classList.NAV_LINK, this.classList.HIDDEN);
      element.classList.add(
        this.type === AdaptiveMenuType.PRIMARY
          ? this.classList.DROPDOWN_TYPOGRAPHY
          : this.classList.PUBLICATION_MENU_LINK
      );
    });
    wrapper.insertAdjacentHTML(
      "beforeend",
      '<div class="dropdown-divider mb-0"></div>'
    );

    return wrapper;
  }
}

export default AdaptiveMenu;

export {
  Elements as AdaptiveMenuElements,
  Selectors as AdaptiveMenuSelectors,
  ClassList as AdaptiveMenuClassList,
  Texts as AdaptiveMenuTexts,
};
